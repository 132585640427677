import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import CriiptoAuth from '@criipto/auth-js'

import { createVuetify } from 'vuetify/lib/framework.mjs'
import 'vuetify/styles'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'
import '@mdi/font/css/materialdesignicons.css'

import store from './store'
import { createI18n } from 'vue-i18n'

import './main.css'

var criiptoAuth = new CriiptoAuth({
  domain: process.env.VUE_APP_CRIIPTO_AUTH_DOMAIN,
  clientID: process.env.VUE_APP_CRIIPTO_AUTH_CLIENT_ID,
  store: sessionStorage
})

const companyTheme = {
  dark: false,
  colors: {
    primary: process.env.VUE_APP_PRIMARY_COLOR,
    secondary: process.env.VUE_APP_SECONDARY_COLOR
  }
}

const i18n = createI18n({
  locale: process.env.VUE_APP_LOCALE,
  fallbackLocale: 'da',
  messages: {
    se: {
      ...require('@/locales/se.json')
      //$vuetify: se
    }
  }
})

const vuetify = createVuetify({
  ssr: true,
  components,
  directives,
  theme: {
    defaultTheme: 'companyTheme',
    themes: {
      companyTheme
    }
  }
})

const app = createApp(App).use(router).use(store).use(vuetify).use(i18n)

app.config.globalProperties.$criiptoAuth = criiptoAuth

app.mount('#app')
