<template>
  <v-app>
    <v-app-bar color="primary" elevation="2">
      <div
        style="max-width: 750px; width: 100%; height: inherit"
        class="mx-auto d-flex align-center justify-left"
      >
        <v-img
          :src="setLogo"
          height="70%"
          max-width="100px"
          style="height: 70%; filter: brightness(0) invert(1)"
        />
        <v-app-bar-title class="pl-4 text-center">
          {{ setTitle }}
        </v-app-bar-title>

        <div style="width: 100px" class="d-flex align-center justify-end">
          <v-btn
            v-if="$route.name !== 'HomeView'"
            class="text-right"
            prepend-icon="mdi-logout"
            stacked
            @click="dialog = true"
          >
            <span style="font-size: 10px">LOGGA UT</span>
          </v-btn>
          <v-dialog v-model="dialog" max-width="300">
            <v-card>
              <v-card-title class="bg-primary"> LOGGA UT </v-card-title>

              <v-card-text class="pa-2 d-flex align-center" style="min-height: 70px">
                Är du säker du vill logga ut?
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text="Nej, stanna"
                  variant="elevated"
                  @click="dialog = false"
                />

                <v-spacer></v-spacer>

                <v-btn color="primary" variant="elevated" text="Ja" @click="goToFrontpage" />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <v-container style="max-width: 750px">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    setTitle() {
      return process.env.VUE_APP_TITLE
    },
    setLogo() {
      return process.env.VUE_APP_COMPANY_LOGO
    },
    ...mapGetters({
      getCriiptoIdentifier: 'getCriiptoIdentifier'
    })
  },
  methods: {
    goToFrontpage() {
      this.dialog = false
      this.$router.push({ name: 'HomeView' })
    }
  }
}
</script>
