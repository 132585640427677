import { createStore } from 'vuex'

import CourseModule from './store/courses'
import MyUserModule from './store/myUser'
import Pages from './store/pages'

const modules = {
  courses: CourseModule,
  myuser: MyUserModule,
  pages: Pages
}

export default createStore({
  modules
})
