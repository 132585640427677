import { getGreenCourse } from '@/api/Courses/GetGreenCourse'
import { submitGreenCourse } from '@/api/Courses/SubmitGreenCourse'

export default {
  state: {
    course: null,
    wrongAnswersQuestionIds: null
  },
  getters: {
    getCourse: (state) => {
      if (state.course) return state.course

      if (localStorage.getItem('course')) state.course = JSON.parse(localStorage.getItem('course'))

      return state.course
    },
    getWrongAnswersQuestionIds: (state) => {
      if (state.wrongAnswersQuestionIds) return state.wrongAnswersQuestionIds

      if (localStorage.getItem('wrongAnswersQuestionIds'))
        state.wrongAnswersQuestionIds = JSON.parse(localStorage.getItem('wrongAnswersQuestionIds'))

      return state.wrongAnswersQuestionIds
    }
  },
  mutations: {
    setCourse: (state, course) => {
      state.course = course

      localStorage.setItem('course', JSON.stringify(course))
    },
    updateCourse: (state, course) => {
      state.course = course

      localStorage.setItem('course', JSON.stringify(course))
    },
    resetCourse: (state) => {
      state.course = null

      localStorage.removeItem('course')
    },
    setWrongAnswersQuestionIds: (state, wrongAnswersQuestionIds) => {
      state.wrongAnswersQuestionIds = wrongAnswersQuestionIds

      localStorage.setItem('wrongAnswersQuestionIds', JSON.stringify(wrongAnswersQuestionIds))
    },
    resetWrongAnswersQuestionIds: (state) => {
      state.wrongAnswersQuestionIds = null

      localStorage.removeItem('wrongAnswersQuestionIds')
    }
  },
  actions: {
    fetchGreenCourse: async ({ commit }, { locationId, criiptoIdentifier }) => {
      const course = await getGreenCourse(locationId, criiptoIdentifier)
      commit('setCourse', course.data)

      return course.data
    },
    submitGreenCourse: async (_, { courseId, questions, criiptoIdentifier, ssn }) => {
      const response = await submitGreenCourse(courseId, questions, criiptoIdentifier, ssn)

      return response.data
    }
  }
}
