import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/callback',
    name: 'CallbackView',
    component: () => import('../views/CallbackView.vue')
  },
  {
    path: '/acceptterms',
    name: 'AcceptTermsView',
    component: () => import('../views/AcceptTermsView.vue')
  },
  {
    path: '/course',
    name: 'CourseView',
    component: () => import('../views/CourseView.vue')
  },
  {
    path: '/review',
    name: 'ResultView',
    component: () => import('../views/ResultView.vue')
  },
  {
    path: '/information',
    name: 'InformationView',
    component: () => import('../views/InformationView.vue')
  },
  {
    path: '/startcourse',
    name: 'StartCourseView',
    component: () => import('../views/StartCourseView.vue')
  },
  {
    path: '/courseend',
    name: 'CourseEndedView',
    component: () => import('../views/CourseEndedView.vue')
  }
]

const router = createRouter({ history: createWebHistory(), routes })

export default router
