import { callApi } from '@/api/apibase'

export const createPrivateCustomer = function (
  criiptoIdentifier,
  name,
  email,
  phoneNumber,
  address,
  zipCode,
  city
) {
  const requestData = {
    CriiptoIdentifier: criiptoIdentifier,
    Name: name,
    PrimaryEmail: email,
    PrimaryPhoneNumber: phoneNumber,
    Address: address,
    ZipCode: zipCode,
    City: city
  }
  return callApi('POST', '/api/greencourse/customer/private/create', requestData)
}
