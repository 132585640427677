import axios from 'axios'

export const callApi = async (method, endpoint, data) => {
  const url = process.env.VUE_APP_API_URL + endpoint

  const headers = {
    'Content-Type': 'application/json'
  }

  const request = {
    method,
    url: url,
    headers: headers,
    cache: 'no-cache'
  }

  if (data) {
    request.data = data
  }

  return await axios(request)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}
