import { callApi } from '@/api/apibase'

export const submitGreenCourse = function (courseId, questions, criiptoIdentifier, ssn) {
  const requestData = {
    Id: courseId,
    Questions: questions,
    CriiptoIdentifier: criiptoIdentifier,
    SSN: ssn
  }
  return callApi('POST', '/api/external/greencourse/submit', requestData)
}
