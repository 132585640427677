import { createPrivateCustomer } from '@/api/Courses/CreatePrivateCustomer'

export default {
  state: {
    givenName: null,
    familyName: null,
    email: null,
    phoneNumber: null,

    address: null,
    zipCode: null,
    city: null,

    fullName: null,
    criiptoIdentifier: null,
    ssn: null
  },
  getters: {
    getGivenName: (state) => {
      if (state.givenName) return state.givenName

      if (localStorage.getItem('givenName')) state.givenName = localStorage.getItem('givenName')

      return state.givenName
    },
    getFamilyName: (state) => {
      if (state.familyName) return state.familyName

      if (localStorage.getItem('familyName')) state.familyName = localStorage.getItem('familyName')

      return state.familyName
    },
    getEmail: (state) => {
      if (state.email) return state.email

      if (localStorage.getItem('email')) state.email = localStorage.getItem('email')

      return state.email
    },
    getPhoneNumber: (state) => {
      if (state.phoneNumber) return state.phoneNumber

      if (localStorage.getItem('phoneNumber'))
        state.phoneNumber = localStorage.getItem('phoneNumber')

      return state.phoneNumber
    },
    getFullName: (state) => {
      if (state.fullName) return state.fullName

      if (localStorage.getItem('fullName')) state.fullName = localStorage.getItem('fullName')

      return state.fullName
    },

    getAddress: (state) => {
      if (state.address) return state.address

      if (localStorage.getItem('address')) state.address = localStorage.getItem('address')

      return state.address
    },
    getZipCode: (state) => {
      if (state.zipCode) return state.zipCode

      if (localStorage.getItem('zipCode')) state.zipCode = localStorage.getItem('zipCode')

      return state.zipCode
    },
    getCity: (state) => {
      if (state.city) return state.city

      if (localStorage.getItem('city')) state.city = localStorage.getItem('city')

      return state.city
    },

    getCriiptoIdentifier: (state) => {
      if (state.criiptoIdentifier) return state.criiptoIdentifier

      if (localStorage.getItem('criiptoIdentifier'))
        state.criiptoIdentifier = localStorage.getItem('criiptoIdentifier')

      return state.criiptoIdentifier
    },
    getSSN: (state) => {
      if (state.ssn) return state.ssn

      if (localStorage.getItem('ssn')) state.ssn = localStorage.getItem('ssn')

      return state.ssn
    }
  },
  mutations: {
    setGivenName: (state, givenName) => {
      state.givenName = givenName

      localStorage.setItem('givenName', givenName)
    },
    setFamilyName: (state, familyName) => {
      state.familyName = familyName

      localStorage.setItem('familyName', familyName)
    },
    setEmail: (state, email) => {
      state.email = email

      localStorage.setItem('email', email)
    },
    setPhoneNumber: (state, phoneNumber) => {
      state.phoneNumber = phoneNumber

      localStorage.setItem('phoneNumber', phoneNumber)
    },
    setFullName: (state, fullName) => {
      state.fullName = fullName

      localStorage.setItem('fullName', fullName)
    },

    setAddress: (state, address) => {
      state.address = address

      localStorage.setItem('address', address)
    },
    setZipCode: (state, zipCode) => {
      state.zipCode = zipCode

      localStorage.setItem('zipCode', zipCode)
    },
    setCity: (state, city) => {
      state.city = city

      localStorage.setItem('city', city)
    },

    setCriiptoIdentifier: (state, criiptoIdentifier) => {
      state.criiptoIdentifier = criiptoIdentifier

      localStorage.setItem('criiptoIdentifier', criiptoIdentifier)
    },
    setSSN: (state, ssn) => {
      state.ssn = ssn

      localStorage.setItem('ssn', ssn)
    },
    clearLocalStorage: () => {
      console.log('clearing local storage')
      localStorage.clear()
    }
  },
  actions: {
    createPrivateCustomer: async (
      _,
      { criiptoIdentifier, name, email, phoneNumber, address, zipCode, city }
    ) => {
      const response = await createPrivateCustomer(
        criiptoIdentifier,
        name,
        email,
        phoneNumber,
        address,
        zipCode,
        city
      )
      return response.data
    }
  }
}
